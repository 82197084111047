/* ===============================
CSS global
===================================*/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	color: #464646;
	font-size: 100%;
	margin: 0;
	line-height: 1.4283;
	font-family: system-ui, -apple-system, Segoe UI, Ubuntu, sans-serif, Helvetica,
		Arial, Apple Color Emoji, Segoe UI Emoji;
}

h1 {
	font-size: 2.75rem;
	font-weight: 700;
	margin-bottom: 2rem;
	letter-spacing: -0.06em;
	font-weight: 700;
	line-height: 1;
	letter-spacing: -0.04em;
}

h2 {
	font-size: 2rem;
}

ul,
ol {
	margin-left: 20px;
}

@media (min-width: 768px) {
	h1 {
		font-size: 6.25rem;
	}
}

.anchor {
	margin-left: 5px;
	color: #9ca3af;
	text-decoration: none;
	visibility: hidden;
	font-weight: 500;
}

*:hover > .anchor {
	visibility: visible;
}

a,
.link {
	color: #ea1980;
	font-weight: 600;
	display: inline;
	text-decoration-line: underline;
}

button {
	background-color: #04aa6d;
	background-color: #ea1980;
	border: none;
	color: white;
	padding: 16px 32px;
	text-decoration: none;
	margin: 4px 2px;
	cursor: pointer;
	font-size: 20px;
	border-radius: 5px;
}

button:disabled {
	background-color: gray;
	cursor: auto;
}

audio {
	filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
	width: 250px;
	max-width: 100%;
	height: 35px;
}

input[type='radio'] {
	accent-color: #ea1980;
	margin-right: 8px;
}

::placeholder {
	color: gray;
}

@media (prefers-color-scheme: dark) {
	* {
		background-color: rgb(20, 20, 20);
	}

	body {
		color: whitesmoke;
	}
}
