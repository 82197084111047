.products {
	padding-bottom: 50px;
	line-height: 1.75;
}

.products_image {
	float: left;
	margin-right: 30px;
	margin-bottom: 10px;
}
.about_image_right {
	float: right;
	margin-left: 30px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.products p {
	margin: 10px 0 15px 0;
}

.secondParagraph {
}

.products li {
	margin-left: 25px;
}

.why {
	margin-top: 50px;
}

.products b {
	color: #ea1980;
	font-weight: 700;
	font-size: large;
}

.logoContainer {
	display: flex;
	justify-content: center;
}

.products .logo {
	width: 135px;
	margin-top: 20px;
}

@media screen and (max-width: 500px) {
	.about_image,
	.about_image_right {
		float: none;
		margin-right: 0;
	}
	.about_image_right {
		margin-left: 0;
		margin-top: 10px;
	}
	.why {
		margin-top: 10px;
	}
}
