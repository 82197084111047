.formContainer {
	padding: 0;
	padding-bottom: 100px;
}
.inputGroup {
	height: 50%;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 10px 0;
}

.inputGroupPicker {
	border: 1px solid gray;
	padding: 20px;
	margin: 20px 0;
}

label {
	text-align: left;
	margin-bottom: 2px;
}

.inputField,
.textarea,
.smtextarea {
	font-size: 18px;
	/* font-family: cursive; */
	background-color: whitesmoke;
	border-radius: 5px;
}

.inputField {
	height: 40px;
	padding-left: 10px;
}

.textarea {
	height: 100px;
	padding: 10px;
}

.smtextarea {
	padding: 10px;
}

.select {
	height: 40px;
	padding-left: 10px;
	background-color: whitesmoke;
}

option {
	background-color: whitesmoke;
}

.submit {
	background-color: #ea1980;
	border: none;
	color: whitesmoke;
	padding: 16px 32px;
	text-decoration: none;
	margin: 4px 2px;
	cursor: pointer;
	font-size: 20px;
	border-radius: 5px;
}

.error {
	color: #ea1980;
}

.success {
	color: #04aa6d;
}

.button_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 30px;
}

.edit_pages_form_subtitle {
	margin: 20px 0;
}

hr {
	color: #ea1980;
	margin: 50px 20px;
}
