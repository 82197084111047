body,
code,
textarea,
input {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

h1,
h2,
header,
footer {
	/* font-family: 'Delius+Swash+Caps'; */
	/* font-family: 'Sunshiney'; */
	/* font-family: 'Twinkle+Star'; */
	/* font-family: 'Indie+Flower', cursive; */
	/* font-family: 'Satisfy'; */
	/* font-family: 'Sacramento', cursive; */
	/* font-family: 'Itim', cursive; */
	font-family: 'Glass Antiqua', cursive;
}
